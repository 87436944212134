import { createSlice } from "@reduxjs/toolkit";
import { getAdminFacilityData } from "./thunk";
import { IAdminFacilityState, IAdminFacility } from "./types";

const initialState: IAdminFacilityState = {
  adminFacility: null,
  error: null,
  status: "idle",
};

export const adminFacilitySlice = createSlice({
  name: "adminFacilitySlice",
  initialState,
  reducers: {
    reset: (state) => {
      state.adminFacility = initialState.adminFacility;
      state.error = initialState.error;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminFacilityData.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getAdminFacilityData.fulfilled, (state, { payload }) => {
      state.adminFacility = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getAdminFacilityData.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload;
      }
      state.status = "idle";
    });
  },
});

export default adminFacilitySlice.reducer;
